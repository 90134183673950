import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { queryClient } from "api/tanStackQuery/queryClient";
import {
  GlobalEventResponse,
  globalEventSchema,
  globalEventsResponseSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { useEffect } from "react";
import { GlobalEventType } from "shared/types/operationalInformation";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import { WebsocketTopic } from "websocket/websocketTypes";

const updateGlobalInfo = (
  message: GlobalEventResponse,
  prev: GlobalEventResponse[],
) => {
  if (!message.isVisible) {
    return prev.filter((info) => info.uuid !== message.uuid);
  }
  return [message, ...prev.filter((info) => info.uuid !== message.uuid)];
};

export const GLOBAL_EVENTS_QUERY_KEY = "globalEvents";

export const useGlobalInformation = () => {
  const globalEventsType: GlobalEventType = "GLOBAL_GENERAL_WARNING";
  const globalEvents = useQuery({
    queryKey: [GLOBAL_EVENTS_QUERY_KEY],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<GlobalEventResponse[]>(
          `${getBackendUrl()}/global-information/visible/NO?type=${globalEventsType}`,
          {
            signal,
          },
        )
        .then((res) => globalEventsResponseSchema.parse(res.data)),
  });

  const { message } = useDropsWebsocket("useGlobalInformation", [
    WebsocketTopic.GLOBAL_INFORMATION,
  ]);

  useEffect(() => {
    if (
      message.status === "received" &&
      message.data.topic === "GLOBAL_INFORMATION"
    ) {
      const globalInfoMessage = globalEventSchema.parse(message.data.message);
      queryClient.setQueryData<GlobalEventResponse[]>(
        [GLOBAL_EVENTS_QUERY_KEY],
        (prev) => {
          if (!prev) {
            return globalInfoMessage.isVisible ? [globalInfoMessage] : [];
          }
          return updateGlobalInfo(globalInfoMessage, prev);
        },
      );
    }
  }, [message]);

  return { globalEvents };
};
