import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import {
  TrainInformationAggregated,
  TrainInformationAggregatedResponse,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import { WebsocketTopic } from "websocket/websocketTypes";

export const useTrainInformation = () => {
  const { trainNumber, trainDate } = useParams();

  const queryKey = ["trainInformation", trainDate, trainNumber];

  const { data, status } = useQuery({
    queryKey,
    queryFn: ({ signal }) =>
      queryFnGET<TrainInformationAggregatedResponse>({
        signal,
        url: `${getBackendUrl()}/trainInformation/train/NO/${trainNumber}/${trainDate}/timeline`,
      }),
    refetchOnWindowFocus: true,
  });

  const { message } = useDropsWebsocket("useTrainInformation", [
    WebsocketTopic.TRAIN_INFORMATION,
  ]);

  // Update state on received websocket message
  useEffect(() => {
    if (
      message.status === "received" &&
      message.data.topic === "TRAIN_INFORMATION" &&
      trainNumber &&
      trainDate
    ) {
      const trainInfoMessage = message.data.message;

      // Only update if the information is about the train currently viewed by the user
      if (
        trainInfoMessage.versions.some(
          (version) =>
            version.trainIdentifier.operational_identifier === trainNumber &&
            version.trainIdentifier.nominal_date,
        )
      ) {
        queryClient.setQueryData<TrainInformationAggregatedResponse>(
          queryKey,
          (prev) => {
            if (!prev) {
              return { trainInformation: [trainInfoMessage] };
            }
            let newState: TrainInformationAggregated[];
            // Check if trainInfoMessage is a later version of an already existing trainInfo.
            const match = prev.trainInformation.find(
              (info) => info.latestInfoUuid === trainInfoMessage.latestInfoUuid,
            );
            // Check if trainInfoMessage replaces an already existing trainInfo.
            const matchReplaced = prev.trainInformation.find(
              (prevInfo) =>
                prevInfo.latestInfoUuid === trainInfoMessage.latestReplacedUuid,
            );
            // If there is a match, only add the new version
            if (match) {
              newState = [
                ...prev.trainInformation.filter(
                  (info) =>
                    info.latestInfoUuid !== trainInfoMessage.latestInfoUuid,
                ),
                {
                  latestInfoUuid: match.latestInfoUuid,
                  versions: [...trainInfoMessage.versions, ...match.versions],
                },
              ];
              // else, if the new version replaces an existing trainInfo, add the new version to the replaced trainInfo
            } else if (matchReplaced) {
              newState = [
                ...prev.trainInformation.filter(
                  (prevInfo) =>
                    prevInfo.latestInfoUuid !==
                    trainInfoMessage.latestReplacedUuid,
                ),
                {
                  latestInfoUuid: trainInfoMessage.latestInfoUuid,
                  versions: [
                    ...trainInfoMessage.versions,
                    ...matchReplaced.versions,
                  ],
                },
              ];
              // else, add as a separate and new trainInformation
            } else {
              newState = [...prev.trainInformation, trainInfoMessage];
            }
            return { trainInformation: newState };
          },
        );
      }
    }
  }, [message]);

  return {
    status,
    data,
  };
};
