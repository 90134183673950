import {
  DayPlanTrackingMessage,
  DropsLogV2Message,
  GlobalInformationMessage,
  InfrastructureInformationMessageData,
  StationInformationMessage,
  TodoMessage,
  TrainInformationMessage,
  TrainResumedMessage,
  OTRMessageData,
  OTRMessage,
  InfrastructureInformationMessage,
} from "websocket/drops/dropsWebsocketMessages";
import { BackendIdentifier } from "websocket/utils";

// TODO(afriestad): MARKED FOR DELETION START
type NotReceived = {
  status: "notReceived";
};

type Received<T> = {
  status: "received";
  data: T;
};

export type OldWebsocketMessage<T> = NotReceived | Received<T>;
// MARKED FOR DELETION END

export enum WebsocketTopic {
  // Both
  STATION_EVENT = "STATION_EVENT", // Includes sensitive messages from Drops backend, but not from Trainmap backend

  // Drops Backend
  TODO = "TODO",
  DROPS_LOG = "DROPS_LOG_V2",
  DAY_PLAN_TRACKING = "DAY_PLAN_TRACKING",
  TRAIN_INFORMATION = "TRAIN_INFORMATION",
  TRAIN_RESUMED = "TRAIN_RESUMED",
  GLOBAL_INFORMATION = "GLOBAL_INFORMATION",
  // AFFECTED_TRAIN = "AFFECTED_TRAIN", // Exists, but no messages are sent on this topic yet

  // Trainmap backend
  OTR = "OTR",
  INFRASTRUCTURE_EVENT = "INFRASTRUCTURE_EVENT",
}

type ValidDropsTopics =
  | WebsocketTopic.STATION_EVENT
  | WebsocketTopic.TODO
  | WebsocketTopic.DROPS_LOG
  | WebsocketTopic.DAY_PLAN_TRACKING
  | WebsocketTopic.TRAIN_INFORMATION
  | WebsocketTopic.TRAIN_RESUMED
  | WebsocketTopic.GLOBAL_INFORMATION;

type ValidTrainmapTopics =
  | WebsocketTopic.STATION_EVENT
  | WebsocketTopic.OTR
  | WebsocketTopic.INFRASTRUCTURE_EVENT;

export type ValidTopicsForBackend = {
  [BackendIdentifier.DROPS_BACKEND]: ValidDropsTopics;
  [BackendIdentifier.TRAINMAP_BACKEND]: ValidTrainmapTopics;
};

type MessageHandlerForTopic<Topic extends WebsocketTopic> = (
  messageData: MessageDataForTopic[Topic],
) => void;

export type MessageHandlersForBackend = {
  [Backend in BackendIdentifier]: {
    [Topic in ValidTopicsForBackend[Backend]]: MessageHandlerForTopic<Topic>;
  };
};

export type MessageDataForTopic = {
  [WebsocketTopic.STATION_EVENT]: StationInformationMessage;
  [WebsocketTopic.TODO]: TodoMessage;
  [WebsocketTopic.DROPS_LOG]: DropsLogV2Message;
  [WebsocketTopic.DAY_PLAN_TRACKING]: DayPlanTrackingMessage;
  [WebsocketTopic.TRAIN_INFORMATION]: TrainInformationMessage;
  [WebsocketTopic.TRAIN_RESUMED]: TrainResumedMessage;
  [WebsocketTopic.GLOBAL_INFORMATION]: GlobalInformationMessage;
  [WebsocketTopic.STATION_EVENT]: StationInformationMessage;
  [WebsocketTopic.OTR]: OTRMessage;
  [WebsocketTopic.INFRASTRUCTURE_EVENT]: InfrastructureInformationMessage;
};

export type TopicsChange = {
  add: WebsocketTopic[];
  remove: WebsocketTopic[];
};

export type WorkerPortId = string;

export type SharedWorkerReturnMessageData<Topic extends WebsocketTopic> =
  | {
      type: "connected";
      id: WorkerPortId;
    }
  | { type: "message"; messageContent: MessageDataForTopic[Topic] }
  | { type: "uninitialised"; original: PortMessageEventData }
  | { type: "reauthenticate" };

export type PortMessageEventData =
  | { type: "add"; id: WorkerPortId; topics: Set<WebsocketTopic> }
  | { type: "remove"; id: WorkerPortId; topics: Set<WebsocketTopic> }
  | { type: "close"; id: WorkerPortId }
  | { type: "token"; token: string };

export enum WebsocketMessageAction {
  SET_TOPICS = "setTopics",
  PING = "ping",
}

export type WebsocketSendMessage =
  | WebsocketSetTopicMessage
  | WebsocketPingMessage;

export type WebsocketSetTopicMessage = {
  action: WebsocketMessageAction.SET_TOPICS;
  topics: TopicsChange;
};

export type WebsocketPingMessage = { action: WebsocketMessageAction.PING };

export type WebsocketReceiveMessage<Topic extends WebsocketTopic> =
  | MessageDataForTopic[Topic]
  | InfrastructureInformationMessageData
  | OTRMessageData;
