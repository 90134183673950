import { CalendarDate, getLocalTimeZone } from "@internationalized/date";
import {
  DatePicker,
  Flex,
  FormControl,
  HStack,
  SearchInput,
} from "@vygruppen/spor-react";
import { isFuture, isYesterday } from "date-fns";
import { format } from "date-fns/format";
import { DayPicker } from "features/VehicleSidebar/VehicleList/DayPicker";
import { RefObject, useEffect, useState } from "react";
import { useDropsRole } from "stores/useDropsRole";
import styled from "styled-components";
import AlternativeTransportList from "./AlternativeTransportList/AlternativeTransportList";
import ReserveAlternativeTransportList from "./ReserveAlternativeTransportList/ReserveAlternativeTransportList";
import TrainList from "./TrainList/TrainList";
import { vehicleListTabs } from "./VehicleTabs";
import FilterButtons from "./common/FilterButtons";
import { useVehicleDate } from "./useVehicleDate";

const ListHeader = styled.div<{ hasSearchQuery: boolean }>`
  padding: 18px 12px;
  align-items: baseline;
  display: grid;
  overflow: visible;
  gap: 6px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colorBackgroundSecondary};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colorBorder};
  outline: 2px solid ${({ theme }) => theme.colorBackgroundMain};
  z-index: 1;
  margin-bottom: 2px;
  position: sticky;
  top: 0;
`;

const sxCenterDatePicker = {
  ".chakra-popover__content": { left: "-50px" },
  ".chakra-popover__arrow": { display: "none" },
};

export const calendarDateToday = () => {
  const todayDate = format(new Date(), "yyyy-MM-dd");
  const [yyyy, MM, dd] = todayDate.split("-").map((it) => parseInt(it, 10));
  return new CalendarDate(yyyy, MM, dd);
};

const showAllVehiclesBtnStates = (
  selectedDate: Date,
  currentSearchQuery: string,
) => {
  if (currentSearchQuery.length > 0) {
    return { disable: true, overrideCheck: true };
  }
  if (isYesterday(selectedDate)) {
    return { disable: true, overrideCheck: true };
  }

  if (isFuture(selectedDate)) {
    return { disable: true, overrideCheck: false };
  }
  return { disable: false, overrideCheck: false };
};

type VehicleListProps = {
  scrollBoxRef: RefObject<HTMLDivElement>;
  vehicleTabIndex: number;
};

const VehicleList = ({ scrollBoxRef, vehicleTabIndex }: VehicleListProps) => {
  const { role: userRole } = useDropsRole();

  const [searchQuery, setSearchQuery] = useState("");
  const vehicleDate = useVehicleDate(new Date());

  const isTrain = vehicleListTabs[vehicleTabIndex].value === "Tog";
  const isBus = vehicleListTabs[vehicleTabIndex].value === "Buss";
  const isReserveBus = vehicleListTabs[vehicleTabIndex].value === "Reservebuss";

  const [showAllVehicles, setShowAllVehicles] = useState(false);

  useEffect(() => {
    if (isYesterday(vehicleDate.selectedDate)) {
      setShowAllVehicles(true);
    } else {
      setShowAllVehicles(false);
    }
  }, [vehicleDate.selectedDate]);

  const showAllVehiclesBtnState = showAllVehiclesBtnStates(
    vehicleDate.selectedDate,
    searchQuery,
  );

  return (
    <Flex flexDir="column" w="100%" minHeight="500px">
      <ListHeader hasSearchQuery={searchQuery.length >= 2}>
        <label htmlFor="vehicle-search" style={{ margin: 0 }}>
          {vehicleListTabs[vehicleTabIndex].label}
        </label>
        <HStack>
          <SearchInput
            id="vehicle-search"
            label="Søk"
            variant="Base"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onReset={() => setSearchQuery("")}
          />
          {isTrain && (
            <FormControl sx={sxCenterDatePicker}>
              <DatePicker
                label="Dato"
                variant="base"
                width="100%"
                defaultValue={calendarDateToday()}
                withPortal={false}
                onChange={(date) => {
                  if (!date) return;
                  vehicleDate.setSelectedDate(date.toDate(getLocalTimeZone()));
                }}
              />
            </FormControl>
          )}
          <DayPicker
            showChangeDayOptions={
              vehicleListTabs[vehicleTabIndex].value !== "Tog"
            }
            vehicleDate={vehicleDate}
          />
        </HStack>
        {!isTrain && (
          <FilterButtons
            showAllVehicles={showAllVehicles}
            setShowAllVehicles={setShowAllVehicles}
            disable={showAllVehiclesBtnState.disable}
            overrideCheck={showAllVehiclesBtnState.overrideCheck}
          />
        )}
      </ListHeader>

      {isTrain && (
        <TrainList
          searchQuery={searchQuery}
          selectedDate={vehicleDate.selectedDate}
        />
      )}
      {isBus && (
        <AlternativeTransportList
          searchQuery={searchQuery}
          selectedDate={vehicleDate.selectedDate}
          showAllVehicles={showAllVehicles}
          scrollBoxRef={scrollBoxRef}
        />
      )}
      {isReserveBus && (
        <ReserveAlternativeTransportList
          searchQuery={searchQuery}
          selectedDate={vehicleDate.selectedDate}
          showAllVehicles={showAllVehicles}
        />
      )}
    </Flex>
  );
};

export default VehicleList;
