import { Environment, environment } from "app/environment/environment";
import { log, LogLevel } from "logging/datadogBrowserLogs";
import {
  InfrastructureInformationMessageData,
  OTRMessageData,
} from "websocket/drops/dropsWebsocketMessages";
import { WebsocketClientProps } from "websocket/useWebsocketClient";
import {
  WebsocketReceiveMessage,
  WebsocketTopic,
} from "websocket/websocketTypes";

export const ONE_MINUTE = 60 * 1000;
export const THIRTY_SECONDS = 30 * 1000;
export const FIVE_SECONDS = 5 * 1000;
export const TWO_SECONDS = 2 * 1000;

export const withBearerToken = (url: string, token: string) =>
  `${url}?authorization=Bearer ${token}`;

export const verboseReadyState = (readyState: number) => {
  switch (readyState) {
    case WebSocket.CONNECTING:
      return "CONNECTING";
    case WebSocket.OPEN:
      return "OPEN";
    case WebSocket.CLOSING:
      return "CLOSING";
    case WebSocket.CLOSED:
      return "CLOSED";
    default:
      return `Unknown readyState: ${readyState}`;
  }
};

export enum BackendIdentifier {
  TRAINMAP_BACKEND = "TRAINMAP_BACKEND",
  DROPS_BACKEND = "DROPS_BACKEND",
}

/**
 * ===============================================
 * ======== DROPS BACKEND WEBSOCKET CONFIG =======
 * ===============================================
 */
const getDropsWebsocketUrl = (): string => {
  const env = environment();

  switch (env) {
    case Environment.Development:
    case Environment.Test:
      return `wss://websocket.test.trafficgui.vydev.io/drops-backend`;
    case Environment.Stage:
      return `wss://websocket.stage.trafficgui.vydev.io/drops-backend`;
    case Environment.Production:
      return `wss://websocket.trafficgui.vydev.io/drops-backend`;
    default:
      throw new Error(`getDropsWebsocketUrl: Unknown environment ${env}`);
  }
};

export const DROPS_WS_PROPERTIES: WebsocketClientProps = {
  url: getDropsWebsocketUrl(),
  pingInterval: ONE_MINUTE,
  pongTimeout: FIVE_SECONDS,
};

/**
 * ===============================================
 * ====== TRAINMAP BACKEND WEBSOCKET CONFIG ======
 * ===============================================
 */
export const getWebsocketReceiveMessage = (
  message: string,
): WebsocketReceiveMessage<WebsocketTopic> | null => {
  try {
    return JSON.parse(message) as WebsocketReceiveMessage<WebsocketTopic>;
  } catch {
    return null;
  }
};

export const isOTRMessageData = (
  messageData: WebsocketReceiveMessage<WebsocketTopic> & { type?: string },
): messageData is OTRMessageData =>
  "type" in messageData && "train" in messageData && "timestamp" in messageData;

export const isInfrastructureInformationMessageData = (
  messageData: WebsocketReceiveMessage<WebsocketTopic> & { type?: string },
): messageData is InfrastructureInformationMessageData =>
  "type" in messageData &&
  "message" in messageData &&
  "topic" in messageData &&
  messageData.topic === "INFRASTRUCTURE_EVENT";

export const getTrainMapWebsocketUrl = (): string => {
  const env = environment();

  switch (env) {
    case Environment.Development:
      return "wss://websocket.test.trafficgui.vydev.io/trainmap-backend";
    case Environment.Test:
      return "wss://websocket.test.trafficgui.vydev.io/trainmap-backend";
    case Environment.Stage:
      return "wss://websocket.stage.trafficgui.vydev.io/trainmap-backend";
    case Environment.Production:
      return "wss://websocket.trafficgui.vydev.io/trainmap-backend";
    default:
      throw new Error(`getTrainMapWebsocketUrl: Unknown environment ${env}`);
  }
};

export const TRAINMAP_WS_PROPERTIES: WebsocketClientProps = {
  url: getTrainMapWebsocketUrl(),
  pingInterval: ONE_MINUTE,
  pongTimeout: FIVE_SECONDS,
};

export function getWebSocketConfig(
  backendIdentifier: BackendIdentifier,
): WebsocketClientProps {
  switch (backendIdentifier) {
    case BackendIdentifier.TRAINMAP_BACKEND:
      return TRAINMAP_WS_PROPERTIES;
    case BackendIdentifier.DROPS_BACKEND:
      return DROPS_WS_PROPERTIES;
    default:
      log(
        LogLevel.error,
        "getWebsocketConfig",
        `Received unknown backend identifier ${backendIdentifier}`,
      );
      throw new Error(
        `Received unknown backend identifier ${backendIdentifier}`,
      );
  }
}
