import {
  NotificationFill30Icon,
  NotificationOutline30Icon,
} from "@vygruppen/spor-icon-react";
import { IconButton } from "@vygruppen/spor-react";
import { TrainInfoState } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import { AlertsModal } from "features/Header/TrainResumed/AlertsModal";
import {
  handleTrainResumedResponse,
  TrainResumed,
} from "features/Header/TrainResumed/useTrainResumed";
import { useRef, useState } from "react";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import { useOnOutsideMouseclick } from "shared/hooks/useOnOutsideMouseclick";
import { useTheme } from "styled-components";
import {
  TrainInformationMessage,
  TrainResumedMessage,
} from "websocket/drops/dropsWebsocketMessages";
import { useWebsocket } from "websocket/useWebsocket";
import { BackendIdentifier } from "websocket/utils";
import { WebsocketTopic } from "websocket/websocketTypes";

export const OpenAlerts = () => {
  const theme = useTheme();

  const [showModal, setShowModal] = useState(false);

  // Consider useStateTrainsResumedMock.tsx if you want trainsResumed(mock) without using websockets
  const [trainsResumed, setTrainsResumed] = useState<TrainResumed[]>([]);
  const hasAlerts = trainsResumed.length > 0;

  const handleTrainResumed = useRef((data: TrainResumedMessage) => {
    handleTrainResumedResponse(data.message, trainsResumed, setTrainsResumed);
  });

  const handleTrainInformation = useRef((data: TrainInformationMessage) => {
    const matchingVersion = data.message.versions.find(
      (version) =>
        version.trainIdentifier.nominal_date &&
        trainsResumed.find(
          (train) =>
            train.trainIdentifier.identifier ===
            version.trainIdentifier.operational_identifier,
        ),
    );

    if (
      matchingVersion?.state === TrainInfoState.CLOSED ||
      matchingVersion?.state === TrainInfoState.REPLACED
    ) {
      const matchingTrainId =
        matchingVersion.trainIdentifier.operational_identifier;
      setTrainsResumed(
        trainsResumed.filter(
          (it) => it.trainIdentifier.identifier !== matchingTrainId,
        ),
      );
    }
  });

  useWebsocket(BackendIdentifier.DROPS_BACKEND, {
    [WebsocketTopic.TRAIN_RESUMED]: handleTrainResumed.current,
    [WebsocketTopic.TRAIN_INFORMATION]: handleTrainInformation.current,
  });

  const { mouseclickRef } = useOnOutsideMouseclick(() => {
    setShowModal(false);
  });

  return (
    <div ref={mouseclickRef} style={{ position: "relative" }}>
      {showModal && (
        <AlertsModal
          trainsResumed={trainsResumed}
          setTrainsResumed={setTrainsResumed}
          setShowModal={setShowModal}
        />
      )}
      {hasAlerts && (
        <AlertsCounter
          position="absolute"
          right="9px"
          top="-3px"
          alerts={trainsResumed.length}
          severity="secondaryAlarm"
          zIndex={1}
        />
      )}
      <IconButton
        mx={2}
        variant="tertiary"
        aria-label="Train alerts"
        icon={
          hasAlerts ? <NotificationFill30Icon /> : <NotificationOutline30Icon />
        }
        onClick={() => {
          setShowModal(!showModal);
        }}
        color={hasAlerts ? theme.colorTextMain : undefined}
      />
    </div>
  );
};
