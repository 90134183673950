import { FC } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { useToast } from "@vygruppen/spor-react";
import {
  GeographicTrainMapTopology,
  Train,
  TrainMap,
} from "@vygruppen/vy-train-map";
import { useAffectedTrainsForMap } from "features/CenterContent/RoleContent/TrainMap/useAffectedTrainsForMap";
import { useInfrastructureInformation } from "features/CenterContent/RoleContent/TrainMap/useInfrastructureInformation";
import { useRealtimeTrains } from "features/CenterContent/RoleContent/TrainMap/useRealtimeTrains";
import { useStationInformation } from "features/CenterContent/RoleContent/TrainMap/useStationInformation";
import * as ROUTES from "shared/utils/routes";
import { useDropsRole } from "stores/useDropsRole";
import { useEventMode, useStretchBuilder } from "stores/useStretchBuilder";
import { useWebsocket } from "websocket/useWebsocket";
import { BackendIdentifier } from "websocket/utils";
import { WebsocketTopic } from "websocket/websocketTypes";

type TrainMapProps = {
  mapHeight: number;
  mapWidth: number;
  topology: GeographicTrainMapTopology;
  stretchBuilderMode: boolean;
  onEventClick: (ids: { infrastructure: string[]; station: string[] }) => void;
};

export const TrainMapRenderer: FC<TrainMapProps> = ({
  mapHeight,
  mapWidth,
  topology,
  stretchBuilderMode,
  onEventClick,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const toast = useToast();
  const eventMode = useEventMode((state) => state.eventMode);

  const [
    clickedStops,
    setClickedStops,
    selectedLegs,
    allStopsSelected,
    setSelectedLegs,
    setSelectedStops,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.setClickedStops,
    state.selectedLegs,
    state.allStopsSelected,
    state.setSelectedLegs,
    state.setSelectedStops,
  ]);

  const { infraInfo, isInfraInfoSuccess, handleInfraWebsocketMessage } =
    useInfrastructureInformation();

  const { stationInfo, isStationInfoSuccess, handleStationWebsocketMessage } =
    useStationInformation();

  useWebsocket(BackendIdentifier.TRAINMAP_BACKEND, {
    [WebsocketTopic.INFRASTRUCTURE_EVENT]: handleInfraWebsocketMessage,
  });

  useWebsocket(BackendIdentifier.DROPS_BACKEND, {
    [WebsocketTopic.STATION_EVENT]: handleStationWebsocketMessage,
  });

  const infraEventIds = infraInfo?.infrastructure_information.map(
    (info) => info.event.incident_id,
  );

  const { affectedTrains, isAffectedTrainsSuccess } = useAffectedTrainsForMap(
    infraEventIds,
    isInfraInfoSuccess,
  );

  const { isVaktleder } = useDropsRole();
  const stretchBuilderEnabled = isVaktleder();

  return (
    <TrainMap
      height={mapHeight}
      width={mapWidth}
      topology={topology}
      useTrains={useRealtimeTrains}
      infrastructureInformation={
        isInfraInfoSuccess && infraInfo
          ? infraInfo
          : { infrastructure_information: [] }
      }
      stationInformation={
        isStationInfoSuccess && stationInfo
          ? stationInfo
          : { station_information: [] }
      }
      affectedTrains={
        isAffectedTrainsSuccess && affectedTrains
          ? affectedTrains
          : { trains_for_event: [] }
      }
      theme={theme.themeName as "light" | "dark"}
      stretchBuilder={
        stretchBuilderEnabled
          ? {
              enabled: eventMode,
              shouldClearSelectedStretches:
                clickedStops.length === 0 && selectedLegs.length === 0,
              setClickedStops,
              setSelectedLegs,
              setSelectedStops,
              eventOnClick: onEventClick,
              allStopsSelected,
            }
          : undefined
      }
      trainOnClick={(train: Train) => {
        if (
          (train.train_id &&
            train.train_date &&
            ["VY", "VYT", "VYG"].includes(train.train_company ?? "")) ||
          !train.train_company
        ) {
          navigate(
            generatePath(ROUTES.TRAIN, {
              countryCode: train.country_code ?? null,
              trainNumber: train.train_id,
              trainDate: train.train_date,
            }),
          );
        } else {
          toast({
            variant: "info",
            text: `Har ikke data på tog ${train.train_id} fra ${train.train_company}`,
          });
        }
      }}
    />
  );
};
