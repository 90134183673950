import { getDropsToken } from "api/dropsLocalStorage";
import { log, LogLevel } from "logging/datadogBrowserLogs";
import { useEffect, useRef, useState } from "react";
import { BackendIdentifier } from "websocket/utils";
import {
  MessageHandlersForBackend,
  PortMessageEventData,
  SharedWorkerReturnMessageData,
  WebsocketTopic,
  ValidTopicsForBackend,
} from "websocket/websocketTypes";

type MessageListener<Backend extends BackendIdentifier> = (
  event: MessageEvent<
    SharedWorkerReturnMessageData<ValidTopicsForBackend[Backend]>
  >,
) => void;

function makeMessageListener<Backend extends BackendIdentifier>(
  sharedWorker: SharedWorker,
  setPortId: (id: string | null) => void,
  messageHandlers: Partial<MessageHandlersForBackend[Backend]>,
  token: string,
): MessageListener<Backend> {
  return ({ data }) => {
    switch (data.type) {
      case "connected": {
        const { id } = data;
        // The worker has confirmed that the connection is set up, we can now subscribe
        const subscribeEvent: PortMessageEventData = {
          type: "add",
          topics: new Set(Object.keys(messageHandlers) as WebsocketTopic[]),
          id,
        };

        sharedWorker.port.postMessage(subscribeEvent);
        setPortId(id);
        return;
      }

      case "uninitialised": {
        // The worker is connected but the websocket is not – give it a token so it can set up
        const tokenEvent: PortMessageEventData = {
          type: "token",
          token,
        };
        sharedWorker.port.postMessage(tokenEvent);

        // Resubmit the original message
        window.setTimeout(
          () => sharedWorker.port.postMessage(data.original),
          500,
        );

        return;
      }

      case "message": {
        // Received a message on one of the subscribed topics
        // We can assume that message is the correct subtype here to equal MessageData
        // because we have type-guards on valid topics for each backend in useWebsocket :)
        const topic = data.messageContent.topic as keyof typeof messageHandlers;
        messageHandlers[topic]?.(data.messageContent);
        return;
      }

      case "reauthenticate": {
        const tokenEvent: PortMessageEventData = {
          type: "token",
          token,
        };
        sharedWorker.port.postMessage(tokenEvent);

        return;
      }

      default:
        log(
          LogLevel.warn,
          `useWebsocket`,
          `Received unknown message data from SharedWorker: ${data}`,
        );
    }
  };
}

// TODO(afriestad): Switch over to use this hook
// TODO(afriestad): Delete all the old, unused WS files
export function useWebsocket<Backend extends BackendIdentifier>(
  backendIdentifier: Backend,
  messageHandlers: Partial<MessageHandlersForBackend[Backend]>,
) {
  const token = getDropsToken();

  const worker = useRef<SharedWorker | null>(null);
  const [portId, setPortId] = useState<string | null>(null);
  const [handlers] = useState(() => messageHandlers); // Save topics on first render so they're stable

  // Set up the SharedWorker connection
  useEffect(() => {
    if (!worker.current) {
      // Initialise connection to shared worker if it doesn't already exist
      worker.current = new SharedWorker(
        new URL("./WebSocketSharedWorker.ts", import.meta.url),
        {
          type: "module",
          name: backendIdentifier,
        },
      );
    } else if (!worker.current.port.onmessage) {
      // Subscribe to message events once the sharedWorker exists
      worker.current.port.addEventListener(
        "message",
        makeMessageListener(
          worker.current,
          setPortId,
          handlers,
          token!, // This might change with the auth rewrite
        ),
      );
      worker.current.port.start();
    }

    // Destructor: tell SharedWorker to unregister, then close port
    return () => {
      if (worker.current && portId) {
        const messageData: PortMessageEventData = {
          type: "close",
          id: portId,
        };
        worker.current.port.postMessage(messageData);
        worker.current.port.close();
      }
    };
  }, [worker.current, portId]);

  useEffect(() => {
    // Whenever the browser gets a network connection after it's been gone,
    // trigger a new websocket connection
    const controller = new AbortController();

    window.addEventListener(
      "online",
      () => {
        if (token) {
          const tokenEvent: PortMessageEventData = {
            type: "token",
            token,
          };
          worker?.current?.port.postMessage(tokenEvent);
        }
      },
      { signal: controller.signal },
    );

    // Destructor: unregister the handlers
    return () => controller.abort();
  }, []);

  return {
    connected: Boolean(worker.current && portId),
  };
}
