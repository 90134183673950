import { useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "api/http/config";
import {
  AffectedTrainsResponse,
  AffectedTrainsResponseSchema,
} from "features/CenterContent/RoleContent/Vaktleder/types";
import { getBackendUrl } from "api/common";
import { WebsocketTopic } from "websocket/websocketTypes";
import { BackendIdentifier } from "websocket/utils";
import { useWebsocket } from "websocket/useWebsocket";

export const useAffectedTrains = () => {
  const affectedTrainsResult = useQuery({
    queryKey: ["affectedTrainsList"],
    queryFn: ({ signal }) =>
      axiosClient
        .get<AffectedTrainsResponse>(`${getBackendUrl()}/affected-trains/NO`, {
          signal,
        })
        .then((res) => AffectedTrainsResponseSchema.parse(res.data)),
    refetchInterval: 5 * 60 * 1000, // Every 5 minutes
  });

  const handleInfraWebsocketMessage = useRef(() => {
    // It takes a while to calculate affected trains, so we delay the refetch a bit.
    // This is a best-effort and there is no guarantee that affected trains is ready by the time
    // we refetch.
    window.setTimeout(() => {
      affectedTrainsResult.refetch();
    }, 7_000);
  });

  const { connected } = useWebsocket(BackendIdentifier.TRAINMAP_BACKEND, {
    [WebsocketTopic.INFRASTRUCTURE_EVENT]: handleInfraWebsocketMessage.current,
  });

  return {
    ...affectedTrainsResult,
    refetch: affectedTrainsResult.refetch,
    websocketConnected: connected,
  };
};
