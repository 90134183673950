import { useQuery } from "@tanstack/react-query";
import { CountryCode, Train } from "@vygruppen/vy-train-map";
import { getTrafficguiBaseUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { LogLevel, log } from "logging/datadogBrowserLogs";
import { OTRMessage } from "websocket/drops/dropsWebsocketMessages";
import { useWebsocket } from "websocket/useWebsocket";
import { BackendIdentifier } from "websocket/utils";
import { WebsocketTopic } from "websocket/websocketTypes";

interface TrainData {
  trains: Train[];
}

const TRAINS_QUERY_KEY = "trains";

const createIndex = (train: Train) =>
  `${train.train_id}_${train.train_date}_${train.country_code}`;

const updateTrain = (message: Train, prev: Train[]): Train[] => [
  ...prev.filter((train) => createIndex(message) !== createIndex(train)),
  message,
];

const deleteTrain = (message: Train, prev: Train[]) =>
  prev.filter((train) => createIndex(train) !== createIndex(message));

const handleOTRMessage = ({ message }: OTRMessage) => {
  const { type, train: newTrain, timestamp } = message;
  switch (type) {
    case "UPDATE":
      queryClient.setQueryData<TrainData>([TRAINS_QUERY_KEY], (prevState) =>
        prevState
          ? { trains: updateTrain(newTrain, prevState.trains) }
          : { trains: [newTrain] },
      );
      break;
    case "DELETE":
      queryClient.setQueryData<TrainData>([TRAINS_QUERY_KEY], (prevState) =>
        prevState
          ? { trains: deleteTrain(newTrain, prevState.trains) }
          : { trains: [] },
      );
      break;
    default:
      log(
        LogLevel.error,
        "useRealtimeTrains/handleOTRMessage",
        `Received message with invalid type: ${type}, at ${timestamp} for train ${createIndex(
          newTrain,
        )}`,
      );
  }
};

export const useRealtimeTrains = (countryCode?: CountryCode) => {
  const { data: trainData, isSuccess: isTrainsSuccess } = useQuery({
    queryKey: [TRAINS_QUERY_KEY],
    retry: 1,
    queryFn: ({ signal }) =>
      queryFnGET<TrainData>({
        signal,
        url: `${getTrafficguiBaseUrl()}/trainmap-backend/trains${
          countryCode ? `?countryCode=${countryCode}` : ""
        }`,
      }),
    refetchOnWindowFocus: true,
  });

  const { connected } = useWebsocket(BackendIdentifier.TRAINMAP_BACKEND, {
    [WebsocketTopic.OTR]: handleOTRMessage,
  });

  return { trainData, isTrainsSuccess, websocketConnected: connected };
};
