/* eslint-disable no-restricted-globals */
// Self is a read-only proxy to `window` in a window context, but also refers to
// other global contexts when not in a browser tab (e.g. SSR or in a Worker).
// – this is necessary for env to be available in WebSocketSharedWorker

enum Environment {
  Development = "DEV",
  Test = "TEST",
  Stage = "STAGE",
  Production = "PROD",
  Unknown = "UNKNOWN",
}

const environment = (): Environment => {
  if (
    import.meta.env.DEV ||
    self.location.origin.includes("localhost") ||
    self.location.origin.includes("127.0.0.1")
  ) {
    return Environment.Development;
  }
  if (self.location.origin.endsWith(".test.trafficgui.vydev.io")) {
    return Environment.Test;
  }
  if (self.location.origin.endsWith(".stage.trafficgui.vydev.io")) {
    return Environment.Stage;
  }
  if (self.location.origin.endsWith("drops.vy.no")) {
    return Environment.Production;
  }
  return Environment.Unknown;
};

export { Environment, environment };
