import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "@vygruppen/spor-react";
import {
  InfrastructureResponse,
  OperationalInfrastructureInfo,
} from "@vygruppen/vy-train-map";
import { getTrafficguiBaseUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { LogLevel, log } from "logging/datadogBrowserLogs";
import { InfrastructureInformationMessage } from "websocket/drops/dropsWebsocketMessages";

const FIVE_MINUTES = 5 * 60 * 1000;
const INFRA_INFO_QUERY_KEY = "infraInfo";
export const useInfrastructureInformation = () => {
  const toast = useToast();

  const {
    data: infraInfo,
    isSuccess: isInfraInfoSuccess,
    status,
  } = useQuery({
    queryKey: [INFRA_INFO_QUERY_KEY],
    retry: 1,
    queryFn: ({ signal }) =>
      queryFnGET<InfrastructureResponse>({
        signal,
        url: `${getTrafficguiBaseUrl()}/trainmap-backend/operational-information/infrastructure-events?countryCode=NO`,
      }),
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    const cleanupOldEvents = setInterval(() => {
      queryClient.setQueryData<InfrastructureResponse>(
        [INFRA_INFO_QUERY_KEY],
        (prev) =>
          prev
            ? {
                infrastructure_information:
                  prev.infrastructure_information.filter(
                    (info) => info.event.active,
                  ),
              }
            : prev,
      );
    }, FIVE_MINUTES);
    return () => clearInterval(cleanupOldEvents);
  }, []);

  useEffect(() => {
    if (status === "error") {
      toast({
        variant: "error",
        text: "Kunne ikke hente infrastrukturbrudd",
      });
    }
  }, [status]);

  const updateInfraInfo = (
    message: OperationalInfrastructureInfo,
    prev: OperationalInfrastructureInfo[],
  ): OperationalInfrastructureInfo[] => {
    const alreadyMatch = prev.some((info) => info.uuid === message.uuid);

    if (message.event.active || (alreadyMatch && !message.event.active)) {
      return [message, ...prev.filter((info) => info.uuid !== message.uuid)];
    }

    return prev;
  };

  const deleteInfraInfo = (
    message: OperationalInfrastructureInfo,
    prev: OperationalInfrastructureInfo[],
  ): OperationalInfrastructureInfo[] =>
    prev.filter((info) => info.uuid !== message.uuid);

  const handleInfraWebsocketMessage = ({
    message: websocketMessage,
  }: InfrastructureInformationMessage) => {
    const { type, message } = websocketMessage;
    switch (type) {
      case "UPDATE":
        queryClient.setQueryData<InfrastructureResponse>(
          [INFRA_INFO_QUERY_KEY],
          (prev) =>
            prev
              ? {
                  infrastructure_information: updateInfraInfo(
                    message,
                    prev.infrastructure_information,
                  ),
                }
              : {
                  infrastructure_information: message.event.active
                    ? [message]
                    : [],
                },
        );
        break;
      case "DELETE":
        queryClient.setQueryData<InfrastructureResponse>(
          [INFRA_INFO_QUERY_KEY],
          (prev) =>
            prev
              ? {
                  infrastructure_information: deleteInfraInfo(
                    message,
                    prev.infrastructure_information,
                  ),
                }
              : {
                  infrastructure_information: [],
                },
        );
        break;
      default:
        log(
          LogLevel.error,
          useInfrastructureInformation.name,
          `Received message with invalid type: ${type} for opInfo with uuid: ${message.uuid}`,
        );
    }
  };

  return {
    infraInfo,
    isInfraInfoSuccess,
    handleInfraWebsocketMessage,
  };
};
