import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { ResultList } from "features/VehicleSidebar/VehicleList/common/ResultList";
import { ResultListWrapper } from "features/VehicleSidebar/VehicleList/common/ResultListWrapper";
import { SearchResultText } from "features/VehicleSidebar/VehicleList/common/SearchResultText";
import { FC, useMemo } from "react";
import { generatePath, useLocation } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { reserveAlternativeTransportListResponseSchema as schema } from "shared/types/alternativeTransport";
import { sortReserveTransport } from "shared/utils/alternativeTransport";
import { getISODrivingFromToTimes } from "shared/utils/dateTimeUtils";
import { RESERVEBUS } from "shared/utils/routes";
import * as z from "zod";
import { ReserveAlternativeTransportElement } from "./ReserveAlternativeTransportElement";
import { filterReserveAlternativeTransport } from "./utils";
import { processTransportList } from "../utils";
import RenderNoDataMessage, {
  RenderMessage,
} from "../common/RenderNoDataMessage";

type ReserveAlternativeTransportListProps = {
  searchQuery: string;
  selectedDate: Date;
  showAllVehicles: boolean;
};

const ReserveAlternativeTransportList: FC<
  ReserveAlternativeTransportListProps
> = ({ searchQuery, selectedDate, showAllVehicles }) => {
  const { from, to } = getISODrivingFromToTimes(selectedDate);
  const { pathname } = useLocation();

  const searchParams = new URLSearchParams({
    to,
    from,
    countryCode: "NO",
  }).toString();

  const url = new URL(
    `${getBackendUrl()}/alternativeTransports/reserve?${searchParams}`,
  ).toString();

  const { status, data } = useQuery({
    queryKey: ["reserveAltTransport", from, to],
    refetchInterval: 30000,
    queryFn: async ({ signal }) =>
      axiosClient
        .get<z.infer<typeof schema>>(url, { signal })
        .then((res) => schema.parse(res.data)),
  });

  const reserveAlternativeTransportList =
    status === "success" ? data.vehicles : [];

  const processedList = processTransportList({
    transportList: reserveAlternativeTransportList,
    showAllVehicles,
    timeKey: "shiftEndTime",
  });

  const filteredReserveAlternativeTransportList = useMemo(
    () =>
      reserveAlternativeTransportList.filter((reserve) =>
        filterReserveAlternativeTransport(reserve, searchQuery),
      ),
    [reserveAlternativeTransportList, searchQuery],
  );

  const listToShow =
    searchQuery.length > 0
      ? filteredReserveAlternativeTransportList
      : processedList;

  switch (status) {
    case "pending":
      return <SkeletonLoader skeletonType="trainList" />;
    case "success":
      return (
        <>
          <SearchResultText
            searchQuery={searchQuery}
            resultLength={filteredReserveAlternativeTransportList.length}
          />
          <ResultListWrapper role="tabpanel">
            <ResultList id="reserveAlternativeTransportList">
              {listToShow.length > 0 ? (
                listToShow
                  .sort(sortReserveTransport)
                  .map((alternativeTransport) => (
                    <ReserveAlternativeTransportElement
                      key={alternativeTransport.id}
                      reserveAlternativeTransport={alternativeTransport}
                      elementSelected={
                        pathname ===
                        generatePath(RESERVEBUS, {
                          vehicleUuid: alternativeTransport.id,
                        })
                      }
                    />
                  ))
              ) : (
                <RenderNoDataMessage
                  processedList={processedList}
                  renderMessage={RenderMessage.Reserve}
                />
              )}
            </ResultList>
          </ResultListWrapper>
        </>
      );
    case "error":
    default:
      return <FailureMessage style={{ margin: "12px" }} />;
  }
};

export default withErrorBoundary(ReserveAlternativeTransportList);
