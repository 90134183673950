import React, { useEffect } from "react";
import { useWebsocketClient } from "websocket/useWebsocketClient";
import { OldWebsocketMessage, WebsocketTopic } from "websocket/websocketTypes";
import { DropsWebsocketMessage } from "websocket/drops/dropsWebsocketMessages";
import { DROPS_WS_PROPERTIES } from "websocket/utils";

export interface DropsWebsocketContextValue {
  sendMessage: (messagePayload: string) => void;
  message: OldWebsocketMessage<DropsWebsocketMessage>;
  register: (name: string, topics: WebsocketTopic[]) => void;
  deregister: (name: string, topics: WebsocketTopic[]) => void;
}

const initialValue: DropsWebsocketContextValue = {
  sendMessage: () => {},
  message: { status: "notReceived" },
  register: () => {},
  deregister: () => {},
};

const DropsWebsocketContext = React.createContext(initialValue);

const DropsWebsocketProvider = (props: any) => {
  const client = useWebsocketClient<DropsWebsocketMessage>(DROPS_WS_PROPERTIES);

  const { children } = props;

  return (
    <DropsWebsocketContext.Provider value={client}>
      {children}
    </DropsWebsocketContext.Provider>
  );
};

const useDropsWebsocket = (name: string, topics: WebsocketTopic[]) => {
  const context = React.useContext(DropsWebsocketContext);

  if (context === undefined) {
    throw new Error("useWebsocket must be used within a WebsocketProvider");
  }

  useEffect(() => {
    context.register(name, topics);
    return () => {
      context.deregister(name, topics);
    };
  }, []);

  return context;
};

export { DropsWebsocketProvider, useDropsWebsocket };
